.long-text-gen-panel {
    .buttons {
        display: flex;
        gap: 5px;
        .span-large {
            flex: 4;
        }
        .span-small {
            flex: 1;
            max-width: 40px;
            .heart-blue {
                width: 24px;
                height: 24px;
            }
        }
    }
    &__block {
        &:not(:last-child) {
            margin-bottom: 30px;
        }

        .panel-set-title {
            display: flex;
            justify-content: space-between;
            gap: 10px;

        }

        .panel-title {
            display: flex;
            gap: 10px;
            margin-bottom: 14px;

            &__icon {
                width: 18px;
                height: 18px;
                flex-shrink: 0;
                color: #757D8A;
                &.title {
                    width: 14px;
                    height: 18px;
                }
            }

            &__text {
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                color: #404D61;
            }
        }

        .panel-paragraph {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #9EA4AD;
        }

        .panel-length {
            display: flex;
            justify-content: space-between;
            gap: 12px;

            .radio {
                width: 100%;

                &__input {
                    display: none;

                    &:checked {
                        & ~ .radio__custom-radio {
                            cursor: default;
                            background: var(--clr-blue-main);
                            color: #FFFFFF;
                        }
                    }
                }

                &__custom-radio {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 35px;
                    background: #F5F8FF;
                    border: 1px solid #E8EEFF;
                    border-radius: 5px;

                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    color: #404D61;
                    padding: 0px 10px;

                    transition: background .3s,
                    color .3s;
                }

                &:checked {
                    &__custom-radio {
                        cursor: default;
                        background: var(--clr-blue-main);
                        color: #FFFFFF;
                    }
                }
            }
        }

        .panel-stats {
            background: #F9FBFF;
            border-radius: 5px;
            padding: 0 20px;

            &__row {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding: 15px 0;

                &:not(:last-child) {
                    border-bottom: 1px solid #E8EEFF;
                }
            }

            &__label {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #757D8A;
            }

            &__value {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #404D61;
            }
        }
    }
}