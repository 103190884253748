.reset-field {
  .modal-label {
    
    color: var(--clr-blue-main);
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    &:hover,
    &:focus {
      color: #757d8a;
    }
  }
  .reset-form {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
    // width: 340px;
    // max-width: 350px;
    gap: 5px;
    margin-bottom: 20px;
    &__input {
      display: flex;
      flex-direction: column;
      // width: 300px;
      input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        line-height: 30px;
        padding: 5px 8px;
        border-radius: 5px;
      }
    }
    // span {
    //   width: 300px;
    // }
    &__error {
      color: red;
    }
    &__info {
      color: green;
    }
    &__button {
      // align-self: center;
      // width: 300px;
      cursor: pointer;
      border: none;
      line-height: 35px;
      border-radius: 2px;
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease;
      &:hover {
        background-color: var(--clr-blue-main);
        color: #fff;
      }
    }
  }
}
