
.site-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__form {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 4px 4px 11px 0 rgba(23, 48, 73, 0.2);
    width: 40%;
  }

}

.card-form {
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    .terms {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }
  }
  &__textarea-description {
    margin-bottom: 10px;
  }
  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    .btn {
      flex: 1;  
    }
  }
}
