.change-lang {
    min-width: 475px;

    &__row {
        &:not(:last-child) {
            margin-bottom: 30px;
        }

        &._buttons {
            display: flex;
            justify-content: flex-end;
            gap: 19px;
        }
    }
}