.team {
  &__collapse-menu {
    .ant-alert-info {
      margin: 10px 0px;
      line-height: 15px;
      max-width: 320px;
    }
    .show-btn {
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #b1afaf;
      padding: 2px 3px;
      border-radius: 5px;
      margin-left: 5px;
    }
    .code {
      cursor: pointer;
    }
    .code-input {
      outline: none;
      border: 1px solid #b1afaf;
      margin-bottom: 10px;
    }

    .blurry-text {
      color: transparent;
      text-shadow: 0 0 5px rgba(0,0,0,0.5);
      
    }
    min-width: 320px;
    .member-line {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &__btn {
        line-height: 10px;
      }

    }
    .team-button:hover {
      border-color: var(--clr-blue-main);
      color: var(--clr-blue-main);
    }
  }
}