.user {
  &__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  table {
    margin-left: -3px;
  }
  &__cancel-subscription {
    transition: all 0.5s ease;
    width: 150px;
    height: 40px;
    background-color: #F2F1F1;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #e4e2e2;
    }
    .loading {
      margin-left: 5px;
    }
  }
  &__cancel-message {
    background-color: #5CCAC0;
    color: #fff;
    padding: 0 5px;
    border-radius: 5px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50vh;
  &__options {
    display: flex;
    flex-direction: column;
  }
  &__option {
    margin-top: 10px;
  }
  &__other {
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:focus {
      outline: none;
    }
    padding: 5px;
    border-radius: 3px;
  }
  &__button {
    width: 60%;
    align-self: center;
    transition: all 0.5s ease;
    cursor: pointer;
    border: none;
    background-color: #0F5EF7;
    color: #fff;
    line-height: 30px;

    border-radius: 5px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: #0942b4;
    }
    margin-top: 10px;
    &.red {
      margin-top: 0px;
      background-color: red;
      &:hover {
        background-color: rgb(206, 35, 35);
      }
    }
  }

}

.form-content {
  &.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__discount {
    background-color: #5CCAC0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 5px;
  }
}

.profile-details{
  display: flex;
  flex-direction: row;
  gap: 7%;
  align-self: center;
  justify-content: center;
}




.user-email{
  grid-row-start: 1;
  grid-row-end: 1;
}

.user-name{
  grid-row-start: 1;
  grid-row-end: 1;
}

.data{
  display: flex;
  justify-content: space-between;

  &__label {
    width: 136px;
    font-weight: 700;
    font-size: 16px;
    line-height: 147%;
  }

  &__value {
    min-width: 134px;
    max-width: none;
    height: 24px;
    font-size: 16px;
    line-height: 147%;
    margin-left: 28px;
    overflow-wrap: break-word;
  }
  &__add {
    display: flex;
    justify-content: right;
    margin-top: 10px;
    margin-left: 16px;
    width: 50px;
    height: 16px;
    color: var(--clr-blue-main);
    cursor: pointer;
  }
}

.data-add-container{
  display: flex;
  min-width: fit-content;
  min-height: fit-content;
}

.data-label-value-container{
  display: flex;
  margin-top: 12px;
}

.profile-layout-content-block{
  height: 100%;
  // flex: 1 1 0%;
  background: white;
  padding: 32px;
  border-radius: 5px;
}
.profile-label{
  width: 100%;
  font-weight: 800;
  font-size: 24px;
  line-height: 132%;
  letter-spacing: 0.01em;
  font-feature-settings: "liga" 0;
  margin-bottom: 12px;
  text-align: center;
}

.profile-description{
  font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.03em;
    color: var(--clr-grey-input);
    margin-bottom: 16px;
}
.plan-data{
  width: fit-content;
}

.plan-data-label-value-container{
  display: flex;
  margin-top: 12px;
  width: 100%;
}

.plan-details{
  width: 100%;
}

.profile-details-content{
  display: flex;
  flex-direction: column;
}
.back-button{
  width: fit-content;
  align-self: left;
  justify-content: left;

}

