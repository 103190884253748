.sidebar-menu {
    margin-bottom: 17px;

    .menu {
        cursor: pointer;
        display: flex;
        align-items: center;

        padding: 12px 20px;
        width: 283px;
        min-height: 53px;
        height: 53px;
        margin-top: 10px;

        background: transparent;
        border-radius: 10px;
        transition: background 0.3s;


        &._special {
            background:  var(--clr-blue);
            .menu__text {
                font-weight: 600;
            }
        }

        &:hover,
        &._active {
            background: var(--clr-blue-main);

            .menu__icon {
                color: #ffffff;
            }

            .menu__text {
                color: #ffffff;
            }

            .menu__counter {
                background: var(--clr-blue-counter-selection);
                color: #ffffff;
            }

            .menu__right-icon {
                color: #FFECEF;
            }
        }

        &__icon {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            color: var(--clr-grey-input);
            transition: color 0.3s;
        }

        &__text {
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: var(--clr-blue-label);
            transition: color 0.3s;
        }

        &__label {
            position: absolute;
            right: 39px;
            color: red;
            display: grid;
            &__text {
                color: #ffffff;
                margin-top: -15px;
                margin-left: 13px;
                font-size: 12px;
            }
        }

        &__counter {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            padding: 0 5px;
            background: var(--clr-blue-counter);
            border-radius: 99px;
            min-width: 25px;
            min-height: 25px;

            font-weight: 1000;
            font-size: 12px;
            color: #ffffff;

            transition: background 0.3s;
        }

        &__right-icon {
            width: 18px;
            height: 18px;
            margin-left: 10px;
            color: #9ea4ad;
        }
    }
    .seo {
        transition: all 0.5s ease;
        border: 1px solid #f59b25;
        .menu__text {
            color: #f59b25;
            &:hover {
                color: white;
            }
        }
        .menu__icon {
            color: #f59b25;
            &:hover {
                color: white;
            }
        }
        &:hover {
            background-color: #f59b25;
        }
    }

    .submenu {
        position: relative;
        cursor: pointer;
        width: 263px;
        height: 53px;

        background: var(--clr-blue-list);
        border-radius: 10px;

        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;

        color: var(--clr-blue-label);

        margin: 10px 0 10px 20px;
        padding-left: 30px;

        transition: color 0.3s;

        &:before {
            content: '';
            position: absolute;
            left: -2px;
            top: 12px;
            bottom: 12px;
            width: 4px;
            background: var(--clr-blue-main);
            border-radius: 10px;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover {
            color: var(--clr-blue-main);
        }

        &._active {
            color: var(--clr-blue-main);

            &:before {
                opacity: 1;
            }
        }
    }
}
