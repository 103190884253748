.success-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .success-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    width: 30%;
    h1 {
      align-self: center;
    }
  }
  .back-button:hover {
    color: var(--clr-blue-main);
    border-color: var(--clr-blue-main);
  }

}