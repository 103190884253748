.long-text-gen {
  position: relative;
  display: flex;
  gap: 20px;
  min-height: 100%;
  color: #404D61;

  &__block {
      position: relative;
      padding: 20px;
  }
  
  &__editor-block {
    z-index: 999;
    margin-top: 30px;
    margin-left: 36px;
    .project-management {
        height: 30px;
        margin-right: 20px;
        &__line {
            min-width: 260px;
        }
        .project-select-results {
            padding: 4px;
            height: 30px;
        }
        .short {
            height: 30px;
            margin-top: 0px;
            .input-wrapper{
            &__input-container {
                .iinput {
                    padding: 4px;
                    min-width: 50px;
                }
                .button-close {
                    margin-top: -5px;
                    width: 20px;
                    height: 20px;
                }
            }
            }
            .button {
                height: 30px;
            }
        }
    }
  }

  &__editor {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      min-width: 750px;
      .guide {
        text-align: right;
        z-index: 1000;
        padding: 10px;
        color: #fff;
        position: relative;
        .save {
            border-radius: 5px;
            margin-right: 5px;
            background-color: var(--clr-blue-main);
            &__icon {
               display: inline;
               align-items: center;
            }
        }
        .green-button {
            background-color: #00aca2;
        }
        span {
            position: inherit;
            top: 29px;
            right: 20px;
            font-size: 15px;
            padding: 5px 10px;
            border-radius: 100%;
            cursor: pointer;
            
            &:hover {
                transition: all 0.5s ease;
                background-color: var(--clr-blue-main);
                color: #ffffff;
            }
        }
        &__icon {
            color: #757d8a;
            svg {
                height: 16px;
                padding-top: 3px;
            }
            margin-right: 3px;
            align-items: center;
            border-radius: 5px !important;
            background-color: (--clr-blue-list);
        }
        &__sign {
            background-color: #00aca2;
        }

        margin-right: 12px;
      }
  }

  &__panel {
      flex-shrink: 0;
      width: 323px;
      height: calc(100vh - 140px);
      overflow: scroll;
  }

  .editor {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
    //   height: calc(100vh - 182px);

      &__toolbar {
          background: #F9FBFF;
          border-radius: 10px;
          padding: 20px;
          border: none;
      }

      &__content {
          height: calc(100% - 20px);
          flex: 1;
          // сдвинуть скролли вправо
          margin-right: -20px;
          padding-right: 20px;
      }
  }
}

.tinput {
  width: 100%;
  padding: 16px 20px 16px 20px;
  margin-top: 16px;
  border-width: 0;
  border-radius: 8px;
  outline: #F0F1F2 solid 1px;
  transition: outline-color .2s;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #757D8A;
  font-family: "Georgia";
  font-size: 30px;
  font-weight: bold;

  &:active,
  &:focus {
      outline: #C3D7FD solid 2px;
  }

  &::placeholder {
      color: rgba(#757D8A, .5);
  }
} 