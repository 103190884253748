.sidebar {
    position: sticky;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 40px);
    top: 20px;
    padding: 25px 20px 0;
    overflow: auto;

    &__logo {

        background: url('~assets/logo.png');
        width: 166px;
        min-height: 51px;
        height: 51px;
        // margin-left: 40px;
        margin-top: 13px;
        margin-bottom: 23px;
    }

    &__menu {
        flex-grow: 1;
    }

    .support {
        display: flex;
        align-items: center;
        padding: 12px 20px;
        margin-bottom: 13px;
        margin-top: -13px;

        color: #9EA4AD;

        // &:hover {
        //     color: #699AFA;
        // }

        &__icon {
            margin-right: 10px;
            height: 22px;
        }
        &__icon_feature {
            margin-right: 8px;
            margin-left: -2px;
            height: 22px;
        }

        &__text {
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
        }
    }
}