.questionnaire {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 90px;
  z-index: 999;
  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__content {
    background-color: #fff;
    border: 2px solid #F0F1F2;
    border-radius: 20px;
    width: 400px;
    height: 347px;
    position: relative;
    display: inline-block;
    padding: 50px;
    .heading {
      text-align: center;
      font-weight: 600;
      font-size: 30px;
      line-height: 100%;
      color: #404d61;
      margin-bottom: 20px;
    }
    .close-label {
      margin-top: 50px;
      margin-bottom: 35px;
    }
    .content-body {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #757D8A;
      height: 149px;
      span {
        display: inline-block;
        width: 270px;
      }
      .question {
        margin-top: 8px;
        font-weight: 550;
        text-align: center;
        width: 300px;
        color: #404d61;
        line-height: 25px;
        font-size: 15px;
      }
      .label {
        margin-top: 5px;
        font-weight: 550;
        text-align: center;
        width: 296px;
        line-height: 25px;
        font-size: 16px;
      }
      .step-5 {
        margin-top: 25px;
      }
    }
    .input-form {
      height: 180px;
      margin-bottom: 5px;
      .input-wrapper {
        position: absolute;
        bottom: 136px;
        left: 58px;
        width: 280px;
      }
    }
    .step-2 {
      margin-top: 9px;
      height: 182px;
      line-height: 24px;
      span {
        display: inline-block;
        width: 296px;
        margin-top: 6px;
      }
    }
    .hide-block {
      position: absolute;
      bottom: 13px;
      left: 141px;
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;
      color: #757D8A;
      cursor: pointer;
      &:hover,
      &:focus {
        text-decoration-line: none;
      }
    }
    .button-block {
      display: flex;
      justify-content: center;
      gap: 55px;
      .button {
        bottom: 0;
      }
    }
  }
}