.dashboard {
    display: grid;
    background: var(--clr-blue-more);
    padding: 20px;
    grid-template-columns: 323px auto;
    grid-template-rows: 80px auto;
    grid-template-areas:
        'sidebar header'
        'sidebar content';
    grid-gap: 20px;

    &__sidebar {
        grid-area: sidebar;
    }

    &__header {
        position: sticky;
        top: 20px;
        grid-area: header;
        z-index: 99;
    }

    &__hider {
        height: 30px;
        width: 100%;
        z-index: 98;
        position: sticky;
        grid-area: header;
        top: 0px;
        background: #f4f7ff;
    }

    &__content {
        grid-area: content;
    }
}
