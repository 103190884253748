.iinput {
    width: 100%;
    padding: 16px 20px 16px 20px;
    border-width: 0;
    border-radius: 8px;
    outline: #F0F1F2 solid 1px;
    transition: outline-color .2s;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #757D8A;

    &:active,
    &:focus {
        outline: var(--clr-blue-background) solid 2px;
    }

    &::placeholder {
        color: rgba(#757D8A, .5);
    }
}

textarea {
    resize: none;
    height: 100%;
}