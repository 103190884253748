.tutorials {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 20px 10px;
  .title {
    margin-top: 10px;
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #404d61;
  }
}
