.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  &:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 20px;
    background: var(--clr-blue-main);
    border-radius: 10px;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__title {
    margin-left: 27px;
  }

  &__affiliate-button {
    margin-left: auto;
  }

  &__plan-button {
    margin-left: auto;
    margin-right: 50px;
  }

  .title {
    display: flex;
    align-items: center;

    &__text {
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      margin: 0;
      color: #404d61;
    }

    &__question {
      margin-left: 15px;
      margin-right: 10px;
    }

    .question {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 25px;
      height: 25px;

      background: var(--clr-blue-counter);
      border-radius: 50%;

      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      color: #ffffff;

      transition: background 0.3s;

      &:hover {
        background: var(--clr-blue-counter-selection);
        color: #ffffff;
      }

      &:hover {
        .question__description {
          display: block;
        }
      }

      &__description {
        position: absolute;
        display: none;
        top: 50%;
        left: calc(100% + 10px);
        width: 245px;
        padding: 10px;
        background: #ffffff;
        border: 1px solid #e8eeff;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
          0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 0px 10px 10px 10px;
        overflow: hidden;
        font-size: 14px;
        line-height: 100%;
        color: #404d61;
      }
    }
  }

  .plan-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: var(--clr-blue-list);
    border-radius: 10px;
    padding: 5px 30px;
    min-height: 53px;
    color: var(--clr-blue-label);
    transition: background 0.3s, color 0.3s;

    &__text {
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;

      &._highlighted {
        color: var(--clr-blue-highlited);
      }
    }

    &__icon {
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }

    &:hover,
    &._active {
      background: var(--clr-blue-main);
      color: #ffffff;

      .plan-button__text {
        color: #ffffff;
      }

      .plan-button__icon {
        color: #ffffff;
      }
    }
  }

  .affiliate-button {
    cursor: pointer;
    background-image: linear-gradient(90deg, #0f5ef7, #ff4c9b 49%, #f9f871);
    box-shadow: 0 3px 6px 0 rgba(19, 17, 26, 0.14);
    display: inline-block;
    padding: 3px;
    border-radius: 10000px;

    &__link-block {
      border: 0;
      display: inline-block;
      padding: 15px 5px;
      min-width: 180px;
      border-radius: 1000px;
      background: #f5f8ff;
      color: #404d61;
      font-size: 14px;
      line-height: 100%;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.01em;
      &__highlighted {
        color: var(--clr-blue-main);
      }
    }

    &:hover,
    &._active {
      padding: 4px;
      margin-right: -1px;

      .affiliate-button__link-block {
        padding: 15px 5px;
        font-size: 14.2px;
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    &__coins {
      align-self: center;
      margin-right: 15px;
      .small-text {
        color: rgba(8, 7, 7, 0.363);
      }
      .coin-text {
        display: inline-block;
        min-width: 35px;
        text-align: right;
      }
      .coins-mode-wrapper {
        display: flex;
        align-items: center;
        .coins-mode {
          display: flex;
          background-color: #F2F1F1;
          border-radius: 10px;
          cursor: pointer;
          span{
            flex: 1;
            border-radius: 10px;
            min-width: 63px;
            padding: 2px 6px;
            text-align: center;
            &.active {
              background-color: var(--clr-blue-main);
              color: #FFF;
              box-shadow: 0px 4px 4px 0px rgba(71, 86, 253, 0.2);
            }
          }
        }
      }
      
    }
    &__coins_icon {
      align-self: center;
      margin-right: 5px;
    }
    &__fullname {
      font-weight: 100;
      font-size: 16px;
      line-height: 100%;
      margin-right: 30px;
      color: #404d61;
    }

    &__avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;
      margin-right: 15px;
      width: 50px;
      height: 50px;
      border-radius: 8px;
      overflow: hidden;
      background: #ccc;
    }
  }

  .profile-menu {
    position: relative;

    &__menu {
      position: absolute;
      top: calc(100% + 11px);
      right: 0;
      .projects {
        cursor: pointer;
      }
      .projects:hover {
        color: #d1d8e4;
        .item__text {
          color: var(--clr-blue-counter);
        }
        // .item__icon_project {
        //   color: #78a3e9;
        // }
      }
    }

    .toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 50px;
      background: var(--clr-blue-list);
      border-radius: 8px;
      transition: background 0.3s;

      &__icon {
        width: 24px;
        height: 24px;
        color: var(--clr-grey-input);
        transition: color 0.3s;
      }

      &:hover,
      &._active {
        background: var(--clr-blue-main);

        .toggle__icon {
          color: #ffffff;
        }
      }
    }

    .menu {
      min-width: 245px;
      background: #ffffff;
      border: 1px solid #e8eeff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 10px 0px 10px 10px;
      overflow: hidden;

      &__list {
        margin-bottom: 30px;
      }

      .gen-counter {
        background: var(--clr-blue-counter);
        padding: 11px 5px 11px 29px;
        margin-bottom: 30px;

        &__text {
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: var(--clr-blue-main);
          margin-right: 10px;
        }

        &__count {
          font-weight: 700;
          font-size: 13px;
          line-height: 100%;
          color: #ffffff;
          padding: 6px 8px;

          background: var(--clr-blue-counter);
          border-radius: 99px;
        }
      }

      .item {
        margin: 25px 10px 25px 30px;
        display: flex;
        align-items: center;

        &__icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          color: #9ea4ad;
        }
        &__icon_project {
          width: 20px;
          height: 20px;
          margin-left: 2px;
          margin-right: 12px;
          color: #9ea4ad;
        }
        &__icon_credit_card {
          width: 20px;
          height: 18px;
          margin-left: 2px;
          margin-right: 12px;
          color: #9ea4ad;
        }

        &__text {
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: #404d61;

          &._highlight {
            color: var(--clr-blue-highlited);
            margin-left: 5px;
          }

          &._bubble {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 25px;
            min-height: 25px;
            padding: 6px 8px;

            font-weight: 700;
            font-size: 13px;
            line-height: 100%;

            background:  var(--clr-blue-main);
            color: #ffffff;
            border-radius: 99px;

            margin-left: 10px;
          }
        }
      }

      .logout {
        cursor: pointer;
        color: #9ea4ad;
        transition: color 0.3s;

        &:hover {
          color: var(--clr-blue-counter);
        }

        &__icon {
          color: currentColor;
        }

        &__text {
          color: currentColor;
        }
      }
    }
  }
}
