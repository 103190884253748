.dropdown {
    position: relative;

    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #757D8A;

    &__selected {
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 1px solid #F0F1F2;
        border-radius: 8px;
        padding: 0 10px 0 20px;
        min-height: 45px;
    }

    &__selected-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #F5F8FF;
        border-radius: 50%;
        margin-left: auto;
    }

    &__selected-icon {
        width: 18px;
        height: 18px;
        color: #757D8A;
    }

    &__icon {
        max-width: 25px;
        max-height: 18px;
        margin-right: 15px;
    }

    &__list {
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        position: absolute;
        background: #FFFFFF;
        border: 1px solid #F0F1F2;
        border-radius: 8px;
        max-height: 200px;
        overflow-y: auto;
        z-index: 1;
    }

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px 20px;

        &:hover {
            background: #F5F8FF;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #F0F1F2;
        }
    }
}