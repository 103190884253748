.result {
    padding: 46px 30px 30px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #404D61;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 28px;
        }
    }

    &__text {
        flex: 1;
        margin-right: 30px;
        padding: 12px 16px;
        min-height: 50px;
        border: 1px solid #F0F1F2;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #757D8A;
        white-space: pre-wrap;
    }

    &__action-btn {
        width: 54px;
        height: 50px;
        padding: 0;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &._save {
            color: #699AFA;

            &:hover {
                color: #FFFFFF;
            }
        }
    }

    &__action-icon {
        width: 24px;
        height: 24px;
    }
}