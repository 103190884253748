:root{
    --clr-blue-main: #0F5EF7;
    --clr-blue-list: #F5F8FF;
    --clr-blue-counter: #699AFA;
    --clr-blue-background: #C3D7FD;
    --clr-blue-stroke: #F0F1F2;
    --clr-grey-input: #757D8A;
    --clr-blue-label: #404D61;
    --clr-blue-counter-selection: #0645BE;
    --clr-blue-highlited: #1890ff;
    --clr-blue-plans: #2b5dee;
    --clr-blue-plans-hover: #2145b1;
    --clr-blue-more: #f4f7ff;
}