.input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    &:hover {
        .input-wrapper__close-button {
            opacity: 1;
        }
    }

    &__header {
        margin-bottom: 15px;
    }

    .iheader {
        display: flex;
        justify-content: space-between;
        margin: 0;

        &__label {
            max-height: 16px;
            display: inline-block;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            color: #404d61;
        }

        &__required {
            color: #ff8080;
        }

        &__counter {
            font-weight: 600;
            font-size: 12px;
            color: var(--clr-blue-counter);
        }

        &__button {
            cursor: pointer;
            float: right;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            border: none;
            height: 20px;
            width: 20px;

            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            margin-left: 5px;
            margin-top: -2px;
            padding: 4px 6.5px;

            background: var(--clr-blue-counter);
            color: #ffffff;

            &:hover {
                background: var(--clr-blue-counter-selection);
                color: var(--clr-blue-main);
            }
        }
    }

    &__label {
        display: inline-block;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #404d61;
        margin-bottom: 15px;
    }

    &__input-container {
        position: relative;
        flex: 1;
    }

    &__close-button {
        position: absolute;
        opacity: 0;
        top: 10px;
        right: 10px;
    }
    &__link {
        transition: all 0.5s ease;
        padding: 5px;
        border: 1px solid #f59b25;
        text-decoration: none;
        color: #f59b25;
        border-radius: 5px;
        line-height: 16px;
        &:hover {
            background-color: #f59b25;
            color: white;
        }
        display: inline-block;
        margin-bottom: 1rem;
        margin-top: -5px;
    }
}
