.site-card-wrapper {
  &__block {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .pricing-card {
    min-height: 770px;
    min-width: 168px;
  }
  
  .card-wrapper {
    position: relative;
  }
}
.profile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 86px;
  .ant-typography {
    font-size: 17px;
  }
  transition: all 0.5s ease;
  &__wrapper {
    display: flex;
    justify-items: space-between;
    align-items: center;
    padding: 0 10px;
  }
  &__logo {
    flex: 10;
  }
  &__navlink {
    flex: 1;
    .active {
      color: var(--clr-blue-main);
    }
    &:hover span{
      color: var(--clr-blue-main);
    }
  }
  .nav-long {
    flex: 2;
    min-width: 230px;
  }
}
.profile-body {
  margin-top: 86px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  min-height: 100vh;
  &__row {
    text-align: center;
    margin-bottom: 25px;
  }
  &__slider {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #F2F1F1;
    gap: 5px;
    border-radius: 10px;
    margin: 15px;
    span {
      font-size: 17px;
    }
    &__month, &__year {
      padding: 10px 20px;
      min-width: 170px;
      text-align: center;
      border-radius: 10px;
      &.active {
        background-color: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
      }
      &.inactive {
        background-color: #F2F1F1;
      }
    }
    
  }
  &__info {
    color: var(--clr-blue-main);
    margin-bottom: 10px;
    margin-top: -10px;
  }
  &__coins-wrapper {
    margin-bottom: 30px;
    width: 38%;
    .coin-details {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .coin {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 20px;
        &__icon-green {
          fill: #00ac73;
        }
        &__icon-yellow {
          fill: #f59b25;
        }
      }
    }
    .card-button-wrapper {
      display: flex;
      justify-content: center;
    }
    .ant-slider-dot-active {
      border-color: rgb(43, 93, 238);
    }

  }
  .checks {
    .ant-slider-dot-active {
      border-color: #00ac73;
    }
  }
  .projects {
    .ant-slider-dot-active {
      border-color: #f59b25;
    }
  }
}
.card-button {
  transition: all 0.5s ease;
  cursor: pointer;
  min-width: 225px;
  background-color: var(--clr-blue-plans);
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  border: none;
  &:hover {
    background-color: var(--clr-blue-plans-hover);
  }
}
.green {
  background-color: #00ac73;
  &:hover {
    background-color: #008f64;
  }
}
.yellow {
  background-color: #f59b25;
  &:hover {
    background-color: #db820d;
  }
}
.card-wrapper {
  &__centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .description {
      // min-height: 40px;
      text-align: center;
    }
  }
  &__price {
    display: flex;
    align-items: flex-end;
    .price {
      font-size: 30px;
      font-weight: 200;
      align-self: flex-end;
      padding: 0;
      margin: 0px 5px -10px 0px;
    }
    .vertical {
      display: flex;
      flex-direction: column;
      gap: 0px;
      span {
        line-height: 15px;
        padding: 0;
      }
    }
  }
  .card-button {
    transition: all 0.5s ease;
    width: 100%;
    background-color: #fff;
    border: 1px solid  var(--clr-blue-plans);
    color: var(--clr-blue-plans);
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    &:hover {
      color: var(--clr-blue-plans);
      border: 1px solid var(--clr-blue-plans);
    }
    &.upgrade {
      color: #fff;
      background-color: var(--clr-blue-plans);
      &:hover {
        background-color:  var(--clr-blue-plans-hover);
        border: 1px solid  var(--clr-blue-plans-hover);
      }
    }
    &.current {
      color: #5CCAC0;
      border: 1px solid #5CCAC0;
      &:hover {
        color: #3b948d;
        border: 1px solid #3b948d;
      }
    }
  }
  &__leftAligned {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .primary {
      font-weight: 500;
      color:#404d61;
    }
  }
}

