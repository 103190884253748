.layout-content {
  font-size: 15px;
  display: flex;
  &__text {
    border-radius: 10px;
    border: 1px solid #E8EEFF;
    flex: 3;
    padding: 10px 20px;
    background-color: #fff;
    .outline {
      font-weight: bold;
      margin: 0;
      font-size: 17px;
      color: #404D61;
    }
  }
  &__buttons {
    .buttons {
      margin: 0 3px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .span-large {
        flex: 4;
      }
      .span-small {
        flex: 1;
        max-width: 40px;
        .heart-blue, .regenerateIcon {
            width: 24px;
            height: 24px;
            opacity: 3;
        }
      }
    }
  }
}

.subtopic {
  margin-bottom: 10px;
}