.loader {

    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;
    }

    &__block {
        border-radius: 8px;
        width: 54px;
        height: 50px;

        background: linear-gradient(90deg, rgba(#F5F8FF, .6) 25%, #F5F8FF 37%, rgba(#F5F8FF, .7) 63%);
        background-size: 400% 100%;
        animation: skeleton-loading 1.4s ease infinite;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &._big {
            flex: 1;
            margin-right: 30px;
        }
    }

    @keyframes skeleton-loading {
        0% {
            background-position: 100% 50%
        }

        to {
            background-position: 0 50%
        }
    }
}