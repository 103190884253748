.plagiarism-content {
  font-size: 15px;
  display: flex;
  gap: 20px;
  &__text {
    position: sticky;
    top: 0px;
    height: 795px;
  }
  &__output {
    width: 50%;
  }
  &__text, &__output {
    min-width: 460px;
    border-radius: 10px;
    border: 1px solid #E8EEFF;
    flex: 3;
    padding: 30px 30px 30px 30px;
    background-color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #757D8A;
    .results {
      display: flex;
      text-align: center;
      margin-bottom: 20px;
      gap: 30px;
      font-weight: 600;
      font-size: 16px;
      .uniqueness,
      .plagiarism {
        border-radius: 5px;
        border: 2px solid #f4f7ff;
        padding: 8px 22px;
        .percentage {
          display: inline-block;
          font-weight: lighter;
          font-size: 37px;
          margin-top: 10px;
        }
      }
      .uniqueness {
        color: rgb(1, 128, 1);
      }
      .plagiarism {
        color: rgb(219, 2, 2);
      }
    }
    &__buttons {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      .balance {
        font-weight: 600;
        font-size: 18px;
        color: #757D8A;
        &__value {
          color: #00aca2;
        }
        .buy-more-button {
          display: inline-block;
          border-radius: 5px;
          background-color: #00aca2;
          text-align: center;
          margin-top: 5px;
          padding: 1px 45px;
          cursor: pointer;
          &:hover, &:focus {
              transition: all 0.5s ease;
              background-color: #f5f8ff;
              .label {
                transition: all 0.5s ease;
                color: #757D8A;
              }
          }
          .label {
            display: inline-block;
            margin-bottom: 0px;
            color: #fff;
            font-size: 14px;
          }
        }
      }
      .span-save {
        margin-top: 5px;
        height: 50px;
        width: 167px;
      }
    }
    .form-input .input-wrapper {
      height: 678px;
    }
    .plagiarised {
      border-bottom: 2px solid red;
      padding: 0 0 2px;
    }
    .similarities-container {
      margin-top: 13px;
      margin-bottom: 10px;
      max-height: 172px;
      overflow: auto;
      padding: 0px 10px;
      background-color: #f4f7ff;
      border: 1px solid #E8EEFF;
      border-bottom: 5px solid #f4f7ff;
      border-radius: 5px;
      text-align: right;
      .expand-button {
        position: sticky;
        top: -4px;
        margin-top: -7px;
        margin-bottom: 2px;
        border-radius: 5px;
        background-color: #00aca2;
        text-align: center;
        height: 17px;
        padding: 0px 4px;
        cursor: pointer;
        box-shadow: 1px 1px #0000002f;
        &:hover {
            transition: all 0.5s ease;
            background-color: #d7d9db;
            .label {
              transition: all 0.5s ease;
              color: #757D8A;
            }
        }
        .label {
          width: 50px;
          display: inline-block;
          margin-bottom: 0px;
          color: #fff;
          font-size: 13px;
        }
      }
      .similarities {
        text-align: left;
        border-width: 0;
        margin-bottom: 5px;
        width: 100%;
        outline: #E8EEFF solid 1px;
        background-color: #fff;
        padding: 5px 8px 5px 8px;
        font-size: 15px;
        font-weight: lighter;
        line-height: 22px;
        .link {
          width: 50px;
        }
        .match {
          color: #494949;
          font-weight: bold;
        }
        hr {
          margin-top: 5px;
          margin-bottom: 5px;
          border: 0px none;
          height: 1px;
          background-color: #E8EEFF;
        }
      }
    }
    .expanded {
      max-height: none;
    }
  }
}