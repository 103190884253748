.action-form {
  width: 400px;
  min-height: 150px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  gap: 10px;
  padding: 10px;
  &__button {
    width: 70%;
    cursor: pointer;
    border: none;
    height: 35px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    &:hover, &.blue {
      background-color: var(--clr-blue-main);
      color: #fff;
    }
  }
  &__error {
    color: red;
    font-size: 15px;
  }
  &__info {
    color: rgba(0, 0, 0, 0.753);
    font-size: 15px;
    text-align: center;
  }
  &__line {
    width: 100%;
    text-align: center;
    input {
      outline: 1px solid rgba(0, 0, 0, 0.055);
      border: none;
      padding: 5px;
      border-radius: 5px;
    }
  }

}