.button-close {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: var(--clr-blue-highlited);
    background: var(--clr-blue-list);
    transition: color .3s, background .3s, opacity .3s;

    &:hover {
        color: white;
        background: var(--clr-blue-main);
    }

    &__icon {
        width: 18px;
        height: 18px;
    }
}