@import 'antd/dist/antd.css';
@import './fonts.scss';
@import './overwrite.scss';
@import './variables.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-family = 'SF Pro Display';
body {
  min-width: 1200px;
  font-family: 'SF Pro Display'!important;
  background: var(--clr-blue-more);
  margin: 0;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

/*Logo*/
.logo img{
  width: 150px;
  margin: 15px;
}

/*Layout background*/
.ant-layout-header {
  background: #fff !important;
  height: 100% !important;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.layout-content-block {
  background: #FFFFFF;
  border: 1px solid #E8EEFF;
  box-sizing: border-box;
  border-radius: 10px;
}

.pricing-card {
    box-shadow: 4px 4px 11px 0 rgba(#173049, .2);
}

.ant-spin-spinning{
  color: var(--clr-blue-counter);
}
.ant-spin-dot-item {
  background-color: var(--clr-blue-counter);
}
