.project-management {
  display: flex;
  margin-left: 10px;
  .project-select-results{
    flex: 2;
    border-width: 0;
    border-radius: 8px;
    outline: #F0F1F2 solid 1px;
    transition: outline-color .2s;
    // padding: 16px 20px 16px 20px;
    padding: 5px 10px;
    width: 50%;
    background: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    // line-height: 22px;
    color: #757D8A;

    &:active,
    &:focus {
      outline: #C3D7FD solid 2px;
    }
    &.mode {
      flex: 1;
    }
  }
  .default {
    color: rgba(#757D8A, .5);
    option {
      color: #757D8A;
    }
  }
  .short {
    display: flex;
    margin-left: 10px;
    margin-top: 0px;
    .input-wrapper {
      min-width: 100px;
      &__header {
        height: 0;
      }
    }
  }
  .span-large {
    flex: 4;
  }
  .span-save {
    margin-top: 5px;
    &_short-button {
      margin-left: 10px;
    }
  }
  .span-mid {
    width: 100%;
    margin-top: 5px;
    &_short-button {
      margin-left: 10px;
      height: 53px;
    }
  }
  &__line {
    display: flex;
    gap: 5px;
    min-width: 290px;
  }
}
.long {
  flex-direction: column;
  margin-left: 0px;
}