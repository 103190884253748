.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;

    &__overflow {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(5, 32, 83, 0.35);
    }

    &__content {
        position: relative;
        display: inline-block;
        padding: 30px;
    }

    &__close-button {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}