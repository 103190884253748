.site-card-wrapper {
  font-size: 16px;
  &__form {
    min-height: 84px;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    position: relative;
    .back-button {
      position: absolute;
      left: 0;
      margin: 15px;
    }
    .search-button,
    .project-select {
      flex: 1;
    }
    .project-select {
      border-width: 0;
      outline: #F0F1F2 solid 1px;
      transition: outline-color .2s;
      padding: 16px 20px 16px 20px;
      background: #fff;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #757D8A;
      cursor: pointer;

      &:active,
      &:focus {
          outline: var(--clr-blue-background) solid 2px;
      }
    }
    .delete-project {
      margin-top: 14.5px;
      border: none;
      outline: none;
      padding: 0px;
      height: 25px;
      .delete-project-icon {
        width: 25px;
        height: 25px;
        color: #757d8a;
        cursor: pointer;
      }
    }
    .filter {
      flex: 3;
      // margin-left: 20px;
    }
    margin-bottom: 20px;
    .text-block-container {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .invisible {
        display: none;
      }
      .text-block {
        border: 1px solid rgb(212, 212, 236);
        border-radius: 5px;
        margin: 10px;
        text-align: justify;
        position: relative;
        .menu {
          z-index: 1000;
          position: absolute;
          background-color: #fff;
          right: 0;
          top: 46px;
          border: 1px solid #e8eeff;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
          border-radius: 10px 0px 10px 10px;
          // overflow: hidden;
          .menu-item {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 10px;
            span {
              cursor: pointer;
              font-weight: 500;
              color: #404d61;
              &:hover {
                color: #0b0d11;
              }
            }
            svg {
              width: 16px;
              height: 16px;
              color: #757d8a;
            }
          }
          
        }
        .tool {
          border-radius: 3px;
          border-bottom: 1px solid rgb(212, 212, 236);
          // background-color: #a1bef6;
          padding: 5px;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          align-items: center;
          
          .tool-name {
            flex: 2;
          }
          .delete {
            color: red;
            cursor: pointer;
            
          }
          .expand {
            color: var(--clr-blue-main);
            cursor: pointer;
            font-weight: 500;
            font-size: 15px;
            &:hover,
            &:focus {
              color: #757d8a;
            }
          }
          .more {
            cursor: pointer;
            background-color: var(--clr-blue-more);
            transition: all 0.5s ease;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding: 4px 0px;
            color: var(--clr-grey-input);
            &:hover {
              background-color: var(--clr-blue-main);
              & > svg{
                color: #fff;
              }
            }
          }
        }
        .content {
          max-height: 200px;
          padding: 5px;
          overflow: auto;
        }
        .input-block {
          max-height: 200px;
          padding: 5px;
          overflow: auto;
          border-radius: 3px;
          border-bottom: 1px solid rgb(212, 212, 236);
          gap: 10px;
          h1 {
            margin-bottom: 0px;
          }
          p {
            margin-bottom: 5px;
          }
          span {
            display: block;
          }
        }
        .expanded {
          max-height: none;
        }
      }
    }
  }
  .back-button:hover {
    color: var(--clr-blue-highlited);
    border-color: var(--clr-blue-highlited);
  }
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(255, 255, 255);
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f8f8;
}

/* Handle */
body ::-webkit-scrollbar-thumb {
  background-color: rgb(236, 236, 236);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(223, 222, 222);
}

.input-name{
  width: fit-content;
    cursor: pointer;
    color: var(--clr-grey-input);
    &:hover{
      color: var(--clr-blue-main);
    }
}