.nav {
  display: flex;
  justify-content: space-around;
  
  .main-text {
    color: rgba(0, 0, 0, 0.322);
    padding: 5px;
    transition: all 0.5s ease;
    cursor: pointer;
    flex: 1;
    &.active {
      color: rgba(0, 0, 0, 0.513);
      background-color: white;
      border-radius: 5px 5px 0px 0px;
      box-shadow: 0px 0px 2px -1px rgba(0, 0, 0, 0.1);
    }
    margin: 0;
  }
}

.logo {
  margin-left: 33px;
}

.login-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  width: 340px;
  max-width: 350px;
  padding: 20px;
  gap: 10px;
  box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.1);
  &__input {
    display: flex;
    flex-direction: column;
    width: 300px;
    input {
      border: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 30px;
      padding: 5px 8px;
      border-radius: 5px;
    }
  }
  span {
    width: 300px;
  }
  &__reset {
    margin-top: -5px;
    margin-bottom: -5px;
    font-size: 12px;
    text-decoration-line: underline;
    color: #757D8A;
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration-line: none;
    }
  }
  &__error {
    color: red;
  }
  &__info {
    color: green;
  }
  &__button {
    align-self: center;
    width: 300px;
    cursor: pointer;
    border: none;
    line-height: 35px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    &.google {
      background-color: white;
      color: #757575;
      &:hover {
        background-color: rgb(241, 241, 241);
        color: #757575;
      }

    }
    &.email {
      background-color: #db4437;
      color: white;
      &:hover {
        background-color: #bd2817;
        color: white;
      }
    }
    &.navigation {
      border: 1px solid #ED1340;
      background-color: #fff;
      color: #ED1340;
      box-shadow: none;
    }
    &:hover, &.blue {
      background-color: var(--clr-blue-main);
      color: #fff;
    } 
    &.blue:hover {
      background-color: #ED1340;
    }
    
  }
  &__navigation {
    transition: all 0.5s ease;
    color: #ED1340;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
    &:hover {
      color: #042c7c;
    }
  }
}

.navtext-wrapper {
  text-align: center;
  margin-top: 10px;
}