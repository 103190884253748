.button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px 28px;
  background: var(--clr-blue-list);
  border-radius: 8px;
  border: none;

  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--clr-grey-input);

  transition: background 0.3s, color 0.3s;

  &__icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  &__icon_coins {
    width: 18px;
    height: 18px;
    margin-left: 2px;
    margin-right: -5px;
  }

  &__coins {
    display: inline-block;
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    background: var(--clr-blue-main);
    color: #ffffff;
  }

  &._blue {
    background: var(--clr-blue-main);
    color: #ffffff;

    &:hover,
    &:focus {
      background: var(--clr-blue-list);
      color: var(--clr-grey-input);
    }
  }

  &._yellow {
    background: #fbf08f;
    color: var(--clr-blue-label);

    &:hover,
    &:focus {
      background: var(--clr-blue-list);
      color: var(--clr-blue-label);
    }
  }

  &._green {
    background: #00aca2;
    color: #ffffff;

    &:hover,
    &:focus {
      background: var(--clr-blue-list);
      color: var(--clr-blue-label);
    }
  }
}
.back {
  margin-bottom: 20px;
}
