.site-card-wrapper {
  &__block-profile {
    display: flex;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    .content {
      margin: 20px;
      display: none;
      &.visible {
        display: block;
      }
    }
    .menu {
      margin: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.105);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      span {
        flex: 1;
        text-align: center;
        padding-bottom: 5px;
        &.active {
          color: var(--clr-blue-main);
          border-bottom: 3px solid  var(--clr-blue-main);
        }
      }
      
    }
    
  }
}
.back-button:hover {
  color:  var(--clr-blue-main);
  border-color:  var(--clr-blue-main);
}
