.keywords-examples-window {
  height: 269px;
  width: 482px;
  letter-spacing: 0.6px;

  &__heading {
    display: inline-block;
    color: #404d61;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    height: 20px;
    width: 411px;
    margin-top: 20px;
    margin-left: 51px;
  }

  &__label {
    margin-left: 51px;
    margin-top: 25px;
    color: var(--clr-blue-main);
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
  }

  &__arrow-1 {
    position: absolute;
    margin-left: 18px;
    margin-top: -15px;
    height: 75px;
  }

  &__arrow-2 {
    position: absolute;
    margin-left: 18px;
    margin-top: 55px;
    height: 95px;
  }

  &__block {
    background: #ffffff;
    border: 3px solid var(--clr-blue-main);
    box-sizing: border-box;
    border-radius: 8px;
    height: 65px;
    width: 411px;

    &__content {
      display: inline-block;
      height: 20px;
      margin-top: 20px;
      margin-left: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #757d8a;
    }

    .first-text {
      width: 430px;
    }

    .second-text {
      width: 371px;
    }
  }

  .first {
    position: absolute;
    margin-left: 51px;
    margin-top: 25px;
  }

  .second {
    position: absolute;
    margin-left: 51px;
    margin-top: 115px;
  }
}
