.reg-form {
  padding: 10px;
  width: 60vw;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 0 20px 20px 20px;
  &__info {
    color:rgba(0, 0, 0, 0.383);
  }
  &__question {
    margin-bottom: 10px;
  }
  &__error {
    color: red;
  }
  &__title {
    margin: 5px;
  }
  &__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
  }
  &__option {
    margin: 5px;
    background-color: #3575f50a;
    color: rgba(31, 31, 31, 0.787);
    line-height: 40px;
    padding: 5px;
  }
  &__button {
    transition: all 0.5s ease;
    cursor: pointer;
    border: none;
    background-color: #ED1340;
    color: #fff;
    line-height: 35px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: #ED1340;
    }
  } 
  &__other {
    border: none;
    width: 100%;
    line-height: 35px;
    border-radius: 5px;
    padding-left: 5px;
    &:focus {
      outline: 1px solid #3a76ee62;
    }
  }
}

.trigger {
  height: 1px;
}