.dashboard-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    &__block {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px;
        .form-examples {
            .title {
                color: #404d61;
                font-weight: bold;
                border-bottom: 1px solid #f5f8ff;
                padding: 3px;
            }
            &__menu {
                display: flex;
                .link {
                    color: #404d61;
                    font-weight: 500;
                    font-size: 16px;
                    padding: 5px 10px;
                    min-width: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 5px;
                    cursor: pointer;
                    &__status {
                        background-color: #c7c9cc;
                        width: 13px;
                        height: 13px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        &.filled {
                            color: var(--clr-blue-main);
                            background-color: #fff;
                        }
                    }
                    border-bottom: 3px solid #0f5cf700;
                    &.active {
                        border-radius: 5px 5px 0px 0px;
                        border-bottom: 0px ;
                        color: var(--clr-blue-main);
                        border-bottom: 3px solid var(--clr-blue-main);
                    }
                }
            }
            &__wrapper {
                border-top: 2px solid #f5f8ff;
                padding: 10px;
                border-radius: 5px;
                .input-wrapper .iheader__label {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }

    &__input {
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    &__textarea-audience {
        height: 160px;
    }

    &__textarea-description {
        height: 209px;
        
        &._small {
            height: 101px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;

        margin-top: 15px;
        .right-button {
            display: flex;
            justify-content: space-between;
            gap: 5px;
        }
        .next-button {
            background-color: #4ebeb5;
            &:hover{  
            background-color: var(--clr-blue-list);
        }
        }
        
    }
    &__examples {
        margin-top: 10px;
    }
    &__description {
        margin-bottom: 10px;
        height: 150px;
    }
}