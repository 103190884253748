@font-face {
    font-family: 'SF Pro Display';
    src: url('~assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('~assets/fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('~assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('~assets/fonts/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('~assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('~assets/fonts/SFProDisplay-Medium.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}