.long-text-gen {
  position: relative;
  display: flex;
  gap: 20px;
  min-height: 100%;
  color: #404d61;

  &__block {
    position: relative;
    padding: 20px;
  }

  &__editor {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__panel {
    flex-shrink: 0;
    width: 323px;
  }

  .editor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    &__toolbar {
      background: #f9fbff;
      border-radius: 10px;
      padding: 20px;
      border: none;
    }

    &__content {
      height: calc(100% - 20px);
      flex: 1;
      // сдвинуть скролли вправо
      margin-right: -20px;
      padding-right: 20px;
    }
  }
}

.tinput {
  width: 100%;
  padding: 16px 20px 16px 20px;
  margin-top: 16px;
  border-width: 0;
  border-radius: 8px;
  outline: #f0f1f2 solid 1px;
  transition: outline-color 0.2s;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #757d8a;
  font-family: 'Georgia';
  font-size: 30px;
  font-weight: bold;

  &:active,
  &:focus {
    outline: #c3d7fd solid 2px;
  }

  &::placeholder {
    color: rgba(#757d8a, 0.5);
  }
}
.article-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex: 1;
    .inactive {
      position: relative;
      p {
        color: rgb(148, 148, 161);
      }
    }
    .inactive:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(100, 100, 120, 0.03);
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    .inactive {
      position: relative;
    }
    .inactive:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(100, 100, 120, 0.05);
    }
  }

  &__input {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  &__textarea-audience {
    height: 160px;
  }

  &__textarea-description {
    height: 400px;
    &._small {
      height: 101px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  &__editor {
    flex: 3;
  }
  &__write-button {
    flex: 1;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__option {
    margin: 2px 4px 2px 0px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    &__label {
      display: flex;
      margin-top: -1px;
    }
    &:first-child {
      margin-left: -1px;
      border-radius: 3px;
    }
    &:last-child {
      border-radius: 3px;
    }
    &.ant-radio-button-wrapper::before {
      width: 0;
    }
    &.ant-radio-button-wrapper-checked {
      background-color: #0f5ef7 !important;
      border-color: #0f5ef7 !important;
    }
  }
  &__other {
    display: flex;
    margin-top: 5px;
    gap: 5px;
    &.tag {
      border: 1px solid rgba(0, 0, 0, 0.116);
      padding: 2px 5px;
      border-radius: 3px;
      &:focus {
        outline: none;
      }
    }
    &.submit {
      transition: all 0.5s ease;
      border: none;
      border-radius: 3px;
      padding: 4px 6px;
      background-color: #f5f8ff;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: #0f5ef7;
        color: #fff;
      }
      align-self: center;
    }
  }
}
.hidden {
  display: none;
}
.loadingLogo {
  position: fixed;
  left: 53%;
  top: 20%;
  z-index: 1000;
  height: 250px;
}

.regenerateIcon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.progressBar {
  width: 100%;
}

.progressBar progress[value] {
  width: 200px;
  appearance: none;
  -webkit-appearance: none;
  padding-top: 4px;
}

.progressBar progress[value]::-webkit-progress-bar {
  height: 10px;
  border-radius: 20px;
  background-color: #fff;
}

.progressBar progress[value]::-webkit-progress-value {
  height: 10px;
  border-radius: 20px;
  background-color: var(--clr-blue-main);
}

.progressBar span {
  margin-left: 10px;
  padding-right: 0px;
}
